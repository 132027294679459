import { urls } from "../config/urls.prod"
import { AppEnvironment } from "@puntaje/shared/core"

export const environment: AppEnvironment = {
    production: true,
    endpoints: {
        base: urls.baseUrl,
        nebulosa: urls.nebuUrl,
        carreras: urls.carrerasUrl,
        achievements: urls.achievementsUrl
    },
    googleAnalyticsPropertyId: {
        alumnos: "UA-173919777-1"
    },
    facebookAnalyticsPropertyId: {
        alumnos: "816023745427775"
    },
    googleTagManagerPropertyId: {
        alumnos: "GTM-KVLJPFK"
    },
    globalSiteTag: {
        alumnos: {
            propertyId: "AW-728533272",
            sendTo: "AW-728533272/jH3RCO_Y66QBEJiSstsC"
        }
    },
    domain: {
        profesores: "www.ust.ogr.cl",
        alumnos: "preparate.santotomas.cl",
        admin: "admin.ust.ogr.cl"
    },
    facebook: {
        appId: "364624623962804",
        apiVersion: "v2.11"
    }
}

export const oldEnvironment = {
    production: true,
    baseUrl: urls.baseUrl,
    nebuUrl: urls.nebuUrl,
    facebookAppId: "364624623962804",
    facebookApiVersion: "v2.11",
    domain: {
        profesores: "www.ust.ogr.cl",
        alumnos: "preparate.santotomas.cl",
        admin: "admin.ust.ogr.cl"
    }
}
