import {
    AmazonType,
    ApiType,
    AppConfiguration,
    AppType,
    AsignaturaType,
    EvaluacionTipoMap,
    HojaRespuestaType,
    PlataformaType,
    CarrerasConf,
    MensajesLoginType,
    RegistroConf,
    ComponentsMap
} from "@puntaje/shared/core"

export class PuntajeConfig implements AppConfiguration {

    planesEstudio = {
        hidePlanesAdaptive: true
    }

    generar = {
        generar: true,
        intrumentosRecomendados: true,
        pruebasPropias: true,
        buscador: true
    }

    navegacion = {
        urlHistorial: ["/evaluaciones/resultados/historial"]
    }
    registro = {
        enableCarreras: true,
        universidades: [
            "Centro de Formación Técnica Santo Tomás",
            "Instituto Profesional Santo Tomás",
            "Universidad Santo Tomás"
        ],
        amount: 3
    } as RegistroConf
    app = {
        assets: {
            logoLarge: "assets/img/logos/logoLarge.png",
            logoMedium: "assets/img/logos/logoMedium.png",
            logoSmall: "assets/img/logos/logoSmall.png",
            logoLargeLight: "assets/img/logos/logoLargeLight.png",
            logoMediumLight: "assets/img/logos/logoMediumLight.png",
            logoSmallLight: "assets/img/logos/logoSmallLight.png",
            logoSquare: "assets/img/logos/logoSquare.png",
            logoInforme: "assets/img/logos/logoSmall.png",
            streamingOffline: ""
        },
        name: "profesores",
        loader: "puntaje",
        routeUser: null,
        routeUserInfo: null,
        paths: {
            pendingInfo: "/pending_info",
            landing: "/landing"
        },
        baseStyle: {
            sharedLayout: {
                backgroundImage: ""
            }
        }
    } as AppType

    amazon = {
        s3BucketUri: "https://s3-us-west-1.amazonaws.com/bucket-puntaje-reloaded/",
        s3BucketUriNebu: "https://s3-us-west-1.amazonaws.com/bucket-nebuloso/"
    } as AmazonType

    api = {
        checkTokenPath: "/check_token",
        authPath: "/authenticate"
    } as ApiType

    asignaturas = [
        {
            id: null,
            asignatura: "Matemática",
            icono: "matematica-4",
            clase: "matematica",
            abreviacion: "Matemática",
            asignaturaPlataforma: "Matemática",
            evaluacionTipo: ["ensayo", "curricular", "diagnostico ensayo", "paes"],
            materialVisible: true
        },
        {
            id: null,
            asignatura: "Lenguaje",
            icono: "lenguaje-4",
            clase: "lenguaje",
            abreviacion: "Lenguaje",
            asignaturaPlataforma: "Lenguaje",
            evaluacionTipo: ["ensayo", "curricular", "diagnostico ensayo", "paes"],
            materialVisible: true
        },
        {
            id: null,
            asignatura: "Historia y Ciencias Sociales",
            icono: "historia-4",
            clase: "historia",
            abreviacion: "Historia",
            asignaturaPlataforma: "Historia y Ciencias Sociales",
            evaluacionTipo: ["ensayo", "curricular", "diagnostico ensayo", "paes"],
            materialVisible: true
        },
        {
            id: null,
            asignatura: "Ciencias PSU",
            icono: "ciencias-4",
            clase: "ciencias",
            abreviacion: "Ciencias",
            asignaturaPlataforma: "Ciencias",
            evaluacionTipo: ["ensayo", "diagnostico ensayo"],
            materialVisible: false
        },
        {
            id: null,
            asignatura: "Ciencias Naturales",
            icono: "ciencias-4",
            clase: "ciencias",
            abreviacion: "Ciencias",
            asignaturaPlataforma: "Ciencias",
            evaluacionTipo: ["curricular", "paes"],
            materialVisible: true,
            onlyMaterialVisible: {
                curricular: true
            }
        },
        {
            id: null,
            asignatura: "Ciencias Física",
            icono: "fisica-4",
            clase: "fisica",
            abreviacion: "Física",
            asignaturaPlataforma: "Ciencias Física",
            evaluacionTipo: ["ensayo", "curricular", "diagnostico ensayo"],
            materialVisible: {
                ensayo: true,
                curricular: false
            }
        },
        {
            id: null,
            asignatura: "Ciencias Química",
            icono: "quimica-5",
            clase: "quimica",
            abreviacion: "Química",
            asignaturaPlataforma: "Ciencias Química",
            evaluacionTipo: ["ensayo", "curricular", "diagnostico ensayo"],
            materialVisible: {
                ensayo: true,
                curricular: false
            }
        },
        {
            id: null,
            asignatura: "Ciencias Biología",
            icono: "biologia-4",
            clase: "biologia",
            abreviacion: "Biología",
            asignaturaPlataforma: "Ciencias Biología",
            evaluacionTipo: ["ensayo", "curricular", "diagnostico ensayo"],
            materialVisible: {
                ensayo: true,
                curricular: false
            }
        }
    ] as AsignaturaType[]

    plataforma = {
        name: "UST",
        id: null,
        pais: "chile",
        lugarTipo: "Comuna",
        lugarLabel: "Comuna",
        identificadorUsuario: ["RUT"],
        identificadorEstablecimiento: ["rbd"],
        grupoUsuarioAlias: "curso",
        info: {
            social: {
                facebook: "https://www.facebook.com/SantoTomas.Admision/",
                twitter: "https://twitter.com/SantoTomas_ST",
                youtube: "https://www.youtube.com/user/SantoTomasChile",
                instagram: "https://www.instagram.com/Santotomas_st/"
            },
            soporte: {
                mail: "contactost@ogr.cl",
                telefono: ""
            },
            contacto: {
                telefono: "",
                mail: "contactost@ogr.cl"
            },
            companyName: "SANTO TOMÁS",
            companySite: "www.ust.cl"
        },
        evaluacionDefault: "paes",
        resumenGeneral: {
            curriculum: "simce",
            generadores: [83, 15, 16, 17, 18, 84, 19, 20, 85]
        },
        tiposInstrumentoInformeUso: ["ensayo", "curricular"],
        evaluacionTipos: ["diagnostico ensayo", "paes"],
        añoInicial: 2018,
        periodos: [
            {
                label: "",
                fechaInicial: [0, 1],
                fechaFinal: [11, 31, 23, 59, 59]
            }
        ],
        streamingDefault: "paes",
        iconSet: {
            noticiasShowcase: "megafono-o",
            establecimientosShowcase: "establecimiento-o",
            cursosShowcase: "comunidad-o"
        },
        instrumentoMensualTipo: "ensayo",
        tiposNotificacion: [
            {
                tipo: "curricular",
                label: "Pruebas"
            },
            {
                tipo: "duda",
                label: "Dudas"
            },
            {
                tipo: "ensayo",
                label: "Ensayos"
            },
            {
                tipo: "respuesta_correcta_duda",
                label: "Respuestas Correctas Dudas"
            },
            {
                tipo: "respuesta_duda",
                label: "Respuestas Dudas"
            }
        ],
        sortClasificacionesFiltro: false,
        canTextHighlight: true
    } as PlataformaType

    evaluaciones = {
        // ensayo, curricular, diagnostico ensayo
        paes: {
            alias: "PAES",
            cantidadPreguntas: 5,
            clasificaciones: {
                estadisticas: ["Eje PAES"],
                estadisticasEvolutivas: ["Eje PAES"],
                desempenoEvaluacion: "Eje PAES",
                groupBy: ["Eje PAES,Unidad Temática PAES"],
                clasificacionTiposTablaEspecificaciones: ["Eje PAES", "Unidad Temática PAES"],
                methods: "clasificaciones",
                referencesTo: {
                    "Unidad Temática PAES": "Eje PAES"
                },
                configuration: [
                    {
                        name: "Eje PAES",
                        htmlId: "Eje_PAES",
                        hasHijos: true,
                        clasificacionTipo: "Eje PAES"
                    }
                ],
                cambiarPregunta: ["nivel 1 prueba de transición"]
            },
            clasificacionDistribucion: "ejes",
            clasificacionMaterial: {
                clasificacion: "Eje PAES",
                label: "eje"
            },
            clasificacionTiposEstadisticas: [
                {
                    nombre: "Eje PAES",
                    alumnos: true,
                    compuesto: true,
                    profesores: true,
                    informeAnual: true
                },
                {
                    nombre: "Unidad Temática PAES",
                    alumnos: true,
                    compuesto: true,
                    profesores: true,
                    informeAnual: false
                },
                {
                    nombre: "Habilidad PAES",
                    alumnos: false,
                    compuesto: true,
                    profesores: true,
                    informeAnual: true
                }
            ],
            cursoTipo: "curso PSU",
            cursos: [
                {
                    label: "7º Medio",
                    clasificacion: "Séptimo Básico"
                },
                {
                    label: "8º Medio",
                    clasificacion: "Octavo Básico"
                },
                {
                    label: "1º Medio",
                    clasificacion: "Primero Medio"
                },
                {
                    label: "2º Medio",
                    clasificacion: "Segundo Medio"
                },
                {
                    label: "3º Medio",
                    clasificacion: "Tercero Medio"
                },
                {
                    label: "4º Medio",
                    clasificacion: "Cuarto Medio"
                }
            ],
            cursosMaterial: [],
            estrellas: [
                {
                    max: 266,
                    min: 150
                },
                {
                    max: 383,
                    min: 267
                },
                {
                    max: 500,
                    min: 384
                },
                {
                    max: 617,
                    min: 501
                },
                {
                    max: 734,
                    min: 618
                },
                {
                    max: 850,
                    min: 735
                }
            ],
            nivelTipoInstrumentoInformeFinal: ["1º Medio", "2º Medio", "3º Medio", "4º Medio"],
            rangoCalificacion: {
                min: 150,
                tickInterval: 50,
                max: 850
            },
            streamingChannelAlias: "Clases Prueba de transición",
            planPersonalClasificacionMateriales: []
        },
        ensayo: {
            alias: "ensayo",
            clasificaciones: {
                estadisticas: ["eje temático", "sub eje tematico"],
                groupBy: ["eje temático,sub eje tematico", "curso", "habilidad cognitiva", "mención"],
                clasificacionTiposTablaEspecificaciones: ["eje temático", "habilidad cognitiva"],
                configuration: [
                    {
                        clasificacionTipo: "eje temático",
                        name: "Eje temático",
                        htmlId: "eje",
                        hasHijos: true
                    },
                    {
                        clasificacionTipo: "curso",
                        name: "Curso",
                        htmlId: "curso",
                        hasHijos: false
                    },
                    {
                        clasificacionTipo: "habilidad cognitiva",
                        name: "Habilidad Cognitiva",
                        htmlId: "hc",
                        hasHijos: false
                    },
                    {
                        clasificacionTipo: "mención",
                        name: "Mención",
                        htmlId: "mencion",
                        hasHijos: false
                    }
                ],
                cambiarPregunta: ["eje temático", "sub eje tematico"]
            },
            clasificacionDistribucion: "ejes",
            clasificacionMaterial: {
                clasificacion: "eje temático",
                label: "eje"
            },
            clasificacionTiposEstadisticas: [
                {
                    nombre: "eje temático",
                    compuesto: false,
                    alumnos: true,
                    profesores: true,
                    informeAnual: true
                },
                {
                    nombre: "sub eje tematico",
                    compuesto: true,
                    alumnos: false,
                    profesores: true,
                    informeAnual: false
                },
                {
                    nombre: "habilidad cognitiva",
                    compuesto: false,
                    alumnos: false,
                    profesores: true,
                    informeAnual: true
                }
            ],
            cursos: [
                { clasificacion: "Primero Medio", label: "1º Medio" },
                { clasificacion: "Segundo Medio", label: "2º Medio" },
                { clasificacion: "Tercero Medio", label: "3º Medio" },
                { clasificacion: "Cuarto Medio", label: "4º Medio" }
            ],
            cursosMaterial: [],
            estrellas: [
                { min: 150, max: 266 },
                { min: 267, max: 383 },
                { min: 384, max: 500 },
                { min: 501, max: 617 },
                { min: 618, max: 734 },
                { min: 735, max: 850 }
            ],
            nivelTipoInstrumentoInformeFinal: ["4º Medio", "3º Medio"],
            rangoCalificacion: {
                min: 150,
                tickInterval: 50,
                max: 850
            },
            streamingChannelAlias: "Clases PSU"
        },
        curricular: {
            alias: "prueba",
            clasificaciones: {
                estadisticas: ["eje temático LGE"],
                groupBy: ["eje temático LGE,objetivo de aprendizaje", "unidad LOCE,aprendizaje esperado"],
                clasificacionTiposTablaEspecificaciones: ["objetivo de aprendizaje", "habilidad cognitiva LGE"],
                configuration: [
                    {
                        clasificacionTipo: "eje temático LGE",
                        name: "Eje de contenidos",
                        htmlId: "eje",
                        hasHijos: true
                    },
                    {
                        clasificacionTipo: "unidad LOCE",
                        name: "Unidad",
                        htmlId: "unidad",
                        hasHijos: true
                    }
                ]
            },
            clasificacionDistribucion: "ejes",
            clasificacionMaterial: {
                clasificacion: "eje temático LGE",
                label: "eje"
            },
            clasificacionTiposEstadisticas: [
                {
                    nombre: "eje temático LGE",
                    compuesto: false,
                    alumnos: true,
                    profesores: true,
                    informeAnual: true
                },
                {
                    // HEY LISTEN! acordarse que hay que manejar este caso de forma general
                    nombre: "aprendizaje esperado",
                    compuesto: false,
                    alumnos: false,
                    profesores: false,
                    informeAnual: true
                }
            ],
            cursos: [
                { clasificacion: "Primero Medio", label: "1º Medio" },
                { clasificacion: "Segundo Medio", label: "2º Medio" },
                { clasificacion: "Tercero Medio", label: "3º Medio" },
                { clasificacion: "Cuarto Medio", label: "4º Medio" }
            ],
            cursosMaterial: [
                { id: 9, clasificacion: "Primero Medio", label: "1º Medio" },
                { id: 10, clasificacion: "Segundo Medio", label: "2º Medio" },
                { id: 11, clasificacion: "Tercero Medio", label: "3º Medio" },
                { id: 12, clasificacion: "Cuarto Medio", label: "4º Medio" }
            ],
            estrellas: [
                { min: 1, max: 2 },
                { min: 2, max: 3 },
                { min: 3, max: 4 },
                { min: 4, max: 5 },
                { min: 5, max: 6 },
                { min: 6, max: 7 }
            ],
            nivelTipoInstrumentoInformeFinal: ["1º Medio", "2º Medio", "3º Medio", "4º Medio"],
            rangoCalificacion: {
                min: 0,
                tickInterval: 25,
                max: 400
            },
            streamingChannelAlias: "Clases Curriculares"
        },
        "diagnostico ensayo": {
            alias: "ensayo de diagnóstico",
            clasificaciones: {
                estadisticas: ["eje temático", "sub eje tematico"],
                groupBy: ["eje temático,sub eje tematico", "curso", "habilidad cognitiva"],
                configuration: [
                    {
                        clasificacionTipo: "eje temático",
                        name: "Eje temático",
                        htmlId: "eje",
                        hasHijos: true
                    }
                ]
            }
        },
        "prueba de transición": {
            alias: "prueba de transición",
            clasificaciones: {
                estadisticas: ["nivel 1 prueba de transición", "nivel 2 prueba de transición"],
                estadisticasEvolutivas: "nivel 1 prueba de transición",
                desempenoEvaluacion: "nivel 1 prueba de transición",
                groupBy: ["nivel 1 prueba de transición,nivel 2 prueba de transición"],
                clasificacionTiposTablaEspecificaciones: [
                    "nivel 1 prueba de transición",
                    "habilidad cognitiva prueba de transición"
                ],
                methods: "clasificaciones",
                referencesTo: {
                    "nivel 2 prueba de transición": "nivel 1 prueba de transición"
                },
                configuration: [
                    {
                        clasificacionTipo: "nivel 1 prueba de transición",
                        name: "Nivel 1",
                        htmlId: "nivel1",
                        hasHijos: true
                    }
                ],
                cambiarPregunta: ["nivel 1 prueba de transición"]
            },
            clasificacionDistribucion: "ejes",
            clasificacionMaterial: {
                clasificacion: "nivel 1 prueba de transición",
                label: "eje"
            },
            clasificacionTiposEstadisticas: [
                {
                    nombre: "nivel 1 prueba de transición",
                    compuesto: true,
                    alumnos: true,
                    profesores: true,
                    informeAnual: true
                },
                {
                    nombre: "nivel 2 prueba de transición",
                    compuesto: true,
                    alumnos: false,
                    profesores: true,
                    informeAnual: false
                },
                {
                    nombre: "habilidad prueba de transición",
                    compuesto: true,
                    alumnos: false,
                    profesores: true,
                    informeAnual: true
                }
            ],
            cursoTipo: "curso PSU",
            cursos: [
                { clasificacion: "Séptimo Básico", label: "7º Medio" },
                { clasificacion: "Octavo Básico", label: "8º Medio" },
                { clasificacion: "Primero Medio", label: "1º Medio" },
                { clasificacion: "Segundo Medio", label: "2º Medio" },
                { clasificacion: "Tercero Medio", label: "3º Medio" },
                { clasificacion: "Cuarto Medio", label: "4º Medio" }
            ],
            cursosMaterial: [],
            estrellas: [
                { min: 150, max: 266 },
                { min: 267, max: 383 },
                { min: 384, max: 500 },
                { min: 501, max: 617 },
                { min: 618, max: 734 },
                { min: 735, max: 850 }
            ],
            nivelTipoInstrumentoInformeFinal: ["1º Medio", "2º Medio", "3º Medio", "4º Medio"],
            rangoCalificacion: {
                min: 150,
                tickInterval: 50,
                max: 850
            },
            streamingChannelAlias: "Clases Prueba de transición"
        }
    } as EvaluacionTipoMap

    instrumentosPredefinidos = {
        zonas: ["Columna 1", "Columna 2", "Columna 3"]
    }

    hojaRespuesta = {
        sectores: [
            [1, 2],
            [2, 3],
            [3, 4],
            [5, 2],
            [2, 7],
            [7, 4],
            [5, 6],
            [6, 7],
            [7, 8]
        ],
        alternativas: 5
    } as HojaRespuestaType

    mensajesLogin = {
        idPlaceholder: "RUT (12345678-9) o Email",
        invalidLoginId: "Debes incluir un RUT o correo electrónico válido.",
        invalidLoginMessage1:
            "<strong>La combinación de RUT/contraseña o correo electrónico/contraseña es incorrecta.</strong> Las contraseñas de ",
        invalidLoginMessage2:
            ' distinguen entre mayúsculas y minúsculas. Por favor, comprueba la tecla "Bloq Mayús". También puedes probar a borrar el caché y las cookies de tu navegador.',
        invalidLoginInactiveEstablishments: `<p>Su cuenta no se encuentra asociada a ningún establecimiento activo.</p>
            <p>Para activar su cuenta le rogamos comunicarse con el administrador de su establecimiento.</p>
            <p>Le dejamos abierta la invitación a acceder a la versión estudiante de la plataforma.</p>`,
        invalidLoginInactiveEstablishmentsRedirectText: "Ir a la versión estudiante"
    }
    carreras: CarrerasConf

    aditionalClass: false

    componentsInSubmodule: ComponentsMap
}
